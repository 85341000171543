@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 200;
    font-style: italic;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-Light.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-LightItalic.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-Italic.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-Black.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    src: url('../Font/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf') format('truetype');
}